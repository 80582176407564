<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12" class="my-2">
        <span
          class="font-weight-medium text-body-1 primary--text text--darken-1"
          >{{ $t("employee.employeeActions") }}</span
        >
      </v-col>
    </v-row>
    <v-row
      no-gutters
      v-if="employeeId && !!model.is_locked && model.user_id2 !== user.id"
    >
      <v-col cols="12" class="my-3">
        <span
          class="
            font-weight-medium
            text-body-1
            primary--text
            text--darken-1
            error--text
          "
          >*{{
            $t("common.documentReadOnlyModeNotice", {
              userId: model.multipleUser.name,
            })
          }}</span
        >
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto" outlined>
          <v-card-title class="py-1">
            <h5
              style="max-width: 220px"
              :class="
                employeeId
                  ? 'px-1 text-truncate font-italic primary--text font-weight-bold'
                  : 'primary--text text--darken-1'
              "
              class="font-weight-regular"
            >
              {{
                employeeId
                  ? originModel.name && `${employeeId}. ${originModel.name}`
                  : $t("employee.addEmployee")
              }}
            </h5>
            <v-spacer />
            <v-menu
              bottom
              v-if="employeeId"
              :close-on-click="true"
              :offset-y="true"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="remarksList && remarksList.length > 0 ? 'success' : 'primary'"
                  class="ma-2"
                  outlined
                  small
                  @click="remarksDialog = true"
                >
                  {{ $t("remarks.remarks") }}
                </v-btn>
                <v-btn
                  color="primary"
                  class="ma-2"
                  small
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :disabled="
                    !valid || (!!model.is_locked && model.user_id2 !== user.id)
                  "
                >
                  <v-icon left> mdi-menu-down </v-icon>
                  {{ $t("quotation.options") }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="accessRight.includes('create')"
                  dense
                  class="primary--text"
                  @click="copyEmployee"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text">
                        mdi-content-copy
                      </v-icon>
                      {{ $t("quotation.copy") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  dense
                  class="primary--text"
                  @click="actDialog = true"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text"> mdi-pulse </v-icon>
                      {{ $t("quotation.activityLog") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  dense
                  class="primary--text"
                  @click="attachedDocumentsDialog = true"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text">
                        mdi-file-document
                      </v-icon>
                      {{ $t("employee.attachedDocuments") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense v-if="accessRight.includes('delete')" class="error--text" @click="confirmationDialog = true, delete_item = employeeId">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="error--text">
                        mdi-delete-sweep-outline
                      </v-icon>
                      {{$t('common.delete')}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              color="primary"
              small
              :loading="loading"
              :disabled="
                !valid || (!!model.is_locked && model.user_id2 !== user.id) || !(user.role.slug === 'admin' || user.role.slug === 'manager') || (accessRight.length < 2 && accessRight.includes('show'))
              "
              @click="saveEmployee"
            >
              {{ $t("employee.save") }}
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="text-h5 pa-0">
            <FormSkeleton
              v-if="
                (employeeId && Object.keys(employeeById).length <= 0) ||
                (copiedEmployee && copyFormLoading)
              "
            />
            <v-form v-else ref="form" v-model="valid" lazy-validation :disabled="(accessRight.length < 2 && accessRight.includes('show'))">
              <Alert :alert.sync="alert" :message="message" />
              <v-row>
                <v-col cols="12">
                  <v-stepper class="pb-0" v-model="e6" vertical non-linear flat>
                    <v-stepper-step
                      class="py-3 px-4 text-h6"
                      :complete="e6 > 1"
                      editable
                      @click.native="accordionStepper(e6)"
                      step="1"
                      :rules="[
                        (value) => !!model.name,
                        (value) =>
                          !model.password ||
                          (model.password &&
                            model.password.length >= 6 &&
                            model.password.length <= 20),
                        (value) => !!model.email,
                        (value) =>
                          !model.email || /.+@.+\..+/.test(model.email),
                        (value) => !!selectedRole,
                      ]"
                    >
                      <h6 class="primary--text font-weight-regular">
                        {{ $t("employee.basicDetails") }}
                      </h6>
                    </v-stepper-step>

                    <v-stepper-content
                      step="1"
                      class="ml-7 mb-auto pb-0 mr-3 py-2 px-4"
                    >
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            class="asterisk"
                            hide-details="auto"
                            v-model.trim="model.name"
                            :label="$t('employee.employeeName')"
                            :rules="employeeValid"
                            required
                          />
                          <v-select
                            hide-details="auto"
                            v-model="selectedGroup"
                            :items="groupValue"
                            item-text="description"
                            item-value="id"
                            return-object
                            :menu-props="{ bottom: true, offsetY: true }"
                            :label="$t('employee.field.group')"
                          />
                          <v-menu
                            ref="startDateMenu"
                            v-model="startDateMenu"
                            :close-on-content-click="false"
                            :return-value.sync="model.starting_date"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                hide-details="auto"
                                v-model="formattedStartDate"
                                :label="$t('employee.field.startDate')"
                                v-bind="attrs"
                                :rules="profileById.date_format ? dateValid : dateValid2"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="model.starting_date"
                              @change="
                                $refs.startDateMenu.save(model.starting_date)
                              "
                            >
                              <v-btn small class="primary" @click="$refs.startDateMenu.save(todayDate())">{{ $t('route.today') }}</v-btn>
                            </v-date-picker>
                          </v-menu>
                          <v-menu
                            ref="endDateMenu"
                            v-model="endDateMenu"
                            :close-on-content-click="false"
                            :return-value.sync="model.end_date"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                hide-details="auto"
                                v-model="formattedEndDate"
                                :label="$t('employee.field.endDate')"
                                v-bind="attrs"
                                :rules="profileById.date_format ? dateValid : dateValid2"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              :min="
                                model.starting_date ? model.starting_date : ''
                              "
                              v-model="model.end_date"
                              @change="$refs.endDateMenu.save(model.end_date)"
                            >
                              <v-btn small class="primary" @click="$refs.endDateMenu.save(todayDate())">{{ $t('route.today') }}</v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-file-input
                            hide-details="auto"
                            @change="previewImage"
                            v-model="profileImage"
                            :rules="filesValid"
                            accept="image/*"
                            :label="$t('employee.field.selectPicture')"
                          >
                            <template v-slot:append-outer>
                              <div>
                                <v-btn
                                  v-if="profileUrl"
                                  icon
                                  @click="imageViewDialog = true"
                                >
                                  <v-icon color="primary"
                                    >mdi-eye-outline</v-icon
                                  >
                                </v-btn>
                              </div>
                              <div>
                                <v-btn
                                  v-if="employeeId && model.media_id"
                                  icon
                                  @click="deleteProfileImage(model.media_id)"
                                >
                                  <v-icon color="error"
                                    >mdi-delete-sweep-outline</v-icon
                                  >
                                </v-btn>
                              </div>
                            </template>
                          </v-file-input>
                          <v-text-field
                            class="asterisk"
                            hide-details="auto"
                            v-model="model.email"
                            :label="$t('employee.field.email')"
                            :rules="emailValid"
                            required
                          />
                          <v-text-field
                            class="asterisk"
                            v-if="
                              user.role.slug === 'admin' || user.role.slug === 'manager' || 
                              user.id === employeeById.user_id
                            "
                            hide-details="auto"
                            v-model="model.password"
                            :label="$t('employee.field.password')"
                            :rules="passwordValid"
                            :append-icon="
                              showPassword ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            :type="showPassword ? 'text' : 'password'"
                            @click:append="showPassword = !showPassword"
                            required
                          />
                          <v-select
                            class="asterisk"
                            :disabled="
                              user.role.slug !== 'admin' &&
                              user.role.slug !== 'manager'
                            "
                            hide-details="auto"
                            v-model="selectedRole"
                            :items="setItemHebrew"
                            :rules="roleValid"
                            required
                            :item-text="locale === 'he' ? 'name_he' : 'name'"
                            item-value="id"
                            return-object
                            :menu-props="{ bottom: true, offsetY: true }"
                            :label="$t('employee.field.role')"
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row class="ma-0 my-2">
                        <v-col cols="12" class="text-center pa-0">
                          <v-btn
                            disabled
                            class="ma-2"
                            outlined
                            fab
                            x-small
                            color="primary"
                          >
                            <v-icon>mdi-chevron-double-up</v-icon>
                          </v-btn>
                          <v-btn
                            class="ma-2"
                            outlined
                            fab
                            x-small
                            color="primary"
                            @click="e6 = 2"
                          >
                            <v-icon>mdi-chevron-double-down</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>

                    <v-stepper-step
                      class="py-3 px-4 text-h6"
                      :complete="e6 > 2"
                      editable
                      @click.native="accordionStepper(e6)"
                      :rules="[
                        (value) =>
                          lessThanValidation(
                            $t('employee.field.po'),
                            $t('common.less'),
                            15,
                            model.po_box
                          ),
                        (value) =>
                          lessThanValidation(
                            $t('employee.field.postalCode'),
                            $t('common.less'),
                            15,
                            model.postal_code
                          ),
                        (value) =>
                          !model.book_keeping_number ||
                          /^[0-9]*$/.test(model.book_keeping_number),
                        (value) => 
                          lessThanValidation(
                            $t('employee.field.bookKeepingNumber'),
                            $t('common.less'),
                            15,
                            model.book_keeping_number
                          ),
                        (value) =>
                          lessThanValidation(
                            $t('employee.field.address'),
                            $t('common.less'),
                            250,
                            model.address
                          ),
                        (value) =>
                          lessThanValidation(
                            $t('employee.field.town'),
                            $t('common.less'),
                            100,
                            model.town
                          ),
                      ]"
                      step="2"
                    >
                        <!-- (value) =>
                          /^[0-9]*$/.test(model.events_days),
                        (value) => 
                          lessThanValidation(
                            $t('employee.field.eventDays'),
                            $t('common.less'),
                            2,
                            model.events_days
                          ), -->
                      <h6 class="primary--text font-weight-regular">
                        {{ $t("employee.otherDetails") }}
                      </h6>
                    </v-stepper-step>

                    <v-stepper-content
                      step="2"
                      class="ml-7 mb-auto pb-0 mr-3 py-2 px-4"
                    >
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            hide-details="auto"
                            v-model="model.address"
                            :label="$t('employee.field.address')"
                            :rules="[
                              lessThanValidation(
                                $t('employee.field.address'),
                                $t('common.less'),
                                250,
                                model.address
                              ),
                            ]"
                            required
                          />
                          <v-text-field
                            hide-details="auto"
                            v-model="model.town"
                            :label="$t('employee.field.town')"
                            :rules="[
                              lessThanValidation(
                                $t('employee.field.town'),
                                $t('common.less'),
                                100,
                                model.town
                              ),
                            ]"
                            required
                          />
                          <v-text-field
                            hide-details="auto"
                            v-model="model.po_box"
                            :label="$t('employee.field.po')"
                            :rules="[
                              lessThanValidation(
                                $t('employee.field.po'),
                                $t('common.less'),
                                15,
                                model.po_box
                              ),
                            ]"
                            required
                          />
                          <v-text-field
                            hide-details="auto"
                            v-model="model.event_days"
                            :label="$t('employee.field.eventDays')"
                            :rules="eventDaysValid"
                            required
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            hide-details="auto"
                            v-model="model.postal_code"
                            :label="$t('employee.field.postalCode')"
                            :rules="[
                              lessThanValidation(
                                $t('employee.field.postalCode'),
                                $t('common.less'),
                                15,
                                model.postal_code
                              ),
                            ]"
                            required
                          />
                          <v-text-field
                            hide-details="auto"
                            v-model="model.book_keeping_number"
                            :label="$t('employee.field.bookKeepingNumber')"
                            :rules="bookkipingNoValid"
                            required
                          />
                          <v-textarea
                            class="mt-4"
                            hide-details="auto"
                            dense
                            v-model="model.general_remarks"
                            :label="$t('customer.field.generalRemarks')"
                          />
                        </v-col>
                      </v-row>
                      <v-row class="ma-0 my-2">
                        <v-col cols="12" class="text-center pa-0">
                          <v-btn
                            class="ma-2"
                            outlined
                            fab
                            x-small
                            color="primary"
                            @click="e6 = 1"
                          >
                            <v-icon>mdi-chevron-double-up</v-icon>
                          </v-btn>
                          <v-btn
                            class="ma-2"
                            outlined
                            fab
                            x-small
                            color="primary"
                            @click="e6 = 3"
                          >
                            <v-icon>mdi-chevron-double-down</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                    <v-stepper-step
                      class="py-3 px-4 text-h6"
                      :complete="e6 > 3"
                      editable
                      @click.native="accordionStepper(e6)"
                      step="3"
                      :rules="[
                        (value) =>
                          lessThanValidation(
                            $t('supplier.field.extension0'),
                            $t('common.less'),
                            6,
                            model.extension_1
                          ),
                        (value) =>
                          !model.phone_1 ||
                          /^[0-9]*$/.test(model.phone_1),
                        (value) =>
                          !model.phone_1 ||
                          (model.phone_1 &&
                            model.phone_1.length >= 7 &&
                            model.phone_1.length <= 17),
                        (value) =>
                          lessThanValidation(
                            $t('supplier.field.dialingArea0'),
                            $t('common.less'),
                            5,
                            model.area_prefix_1
                          ),
                        (value) =>
                          lessThanValidation(
                            $t('supplier.field.countryPrefix0'),
                            $t('common.less'),
                            5,
                            model.country_prefix_1
                          ),
                        (value) =>
                          lessThanValidation(
                            $t('supplier.field.extension0'),
                            $t('common.less'),
                            6,
                            model.extension_2
                          ),
                        (value) =>
                          !model.phone_2 ||
                          /^[0-9]*$/.test(model.phone_2),
                        (value) =>
                          !model.phone_2 ||
                          (model.phone_2 &&
                            model.phone_2.length >= 7 &&
                            model.phone_2.length <= 17),
                        (value) =>
                          lessThanValidation(
                            $t('supplier.field.dialingArea0'),
                            $t('common.less'),
                            5,
                            model.area_prefix_2
                          ),
                        (value) =>
                          lessThanValidation(
                            $t('supplier.field.countryPrefix0'),
                            $t('common.less'),
                            5,
                            model.country_prefix_2
                          ),
                        (value) =>
                          lessThanValidation(
                            $t('supplier.field.extension0'),
                            $t('common.less'),
                            6,
                            model.extension_3
                          ),
                        (value) =>
                          lessThanValidation(
                            $t('supplier.field.dialingArea0'),
                            $t('common.less'),
                            5,
                            model.area_prefix_3
                          ),
                        (value) =>
                          lessThanValidation(
                            $t('supplier.field.countryPrefix0'),
                            $t('common.less'),
                            5,
                            model.country_prefix_3
                          ),
                        (value) =>
                          !model.phone_3 ||
                          /^[0-9]*$/.test(model.phone_3),
                        (value) =>
                          !model.phone_3 ||
                          (model.phone_3 &&
                            model.phone_3.length >= 7 &&
                            model.phone_3.length <= 17),
                        (value) =>
                          lessThanValidation(
                            $t('supplier.field.dialingArea0'),
                            $t('common.less'),
                            5,
                            model.area_prefix_4
                          ),
                        (value) =>
                          !model.cellular_no_1 ||
                          /^[0-9]*$/.test(model.cellular_no_1),
                        (value) =>
                          !model.cellular_no_1 ||
                          (model.cellular_no_1 &&
                            model.cellular_no_1.length >= 7 &&
                            model.cellular_no_1.length <= 17),
                        (value) =>
                          lessThanValidation(
                            $t('supplier.field.countryPrefix0'),
                            $t('common.less'),
                            5,
                            model.country_prefix_4
                          ),
                        (value) =>
                          lessThanValidation(
                            $t('supplier.field.dialingArea0'),
                            $t('common.less'),
                            5,
                            model.area_prefix_5
                          ),
                        (value) =>
                          !model.cellular_no_2 ||
                          /^[0-9]*$/.test(model.cellular_no_2),
                        (value) =>
                          !model.cellular_no_2 ||
                          (model.cellular_no_2 &&
                            model.cellular_no_2.length >= 7 &&
                            model.cellular_no_2.length <= 17),
                        (value) =>
                          lessThanValidation(
                            $t('supplier.field.countryPrefix0'),
                            $t('common.less'),
                            5,
                            model.country_prefix_5
                          ),
                        (value) =>
                          lessThanValidation(
                            $t('supplier.field.countryPrefix0'),
                            $t('common.less'),
                            5,
                            model.country_prefix_6
                          ),
                        (value) =>
                          lessThanValidation(
                            $t('supplier.field.dialingArea0'),
                            $t('common.less'),
                            5,
                            model.area_prefix_6
                          ),
                        (value) =>
                          !model.fax ||
                          /^[0-9]*$/.test(model.fax),
                        (value) =>
                          !model.fax ||
                          (model.fax &&
                            model.fax.length >= 7 &&
                            model.fax.length <= 17),
                      ]"
                    >
                      <h6 class="primary--text font-weight-regular">
                        {{ $t("employee.displayText") }}
                      </h6>
                    </v-stepper-step>

                    <v-stepper-content
                      step="3"
                      class="ml-7 mb-auto pb-0 mr-3 py-2 px-4"
                    >
                      <v-row>
                        <v-col cols="12">
                          <v-simple-table dense height="340px" fixed-header>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th style="width: 120px">
                                    {{ $t("employee.communication") }}
                                  </th>
                                  <th>
                                    {{ $t("employee.country") }}
                                  </th>
                                  <th>
                                    {{ $t("employee.areaCode") }}
                                  </th>
                                  <th>
                                    {{ $t("employee.no") }}
                                  </th>
                                  <th>
                                    {{ $t("employee.extension") }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{{ $t("employee.tel") }}</td>
                                  <td>
                                    <v-text-field
                                      hide-details="auto"
                                      v-model="model.country_prefix_1"
                                      :rules="[
                                        lessThanValidation(
                                          $t('employee.field.countryPrefix0'),
                                          $t('common.less'),
                                          5,
                                          model.country_prefix_1
                                        ),
                                      ]"
                                      required
                                    />
                                  </td>
                                  <td>
                                    <v-text-field
                                      hide-details="auto"
                                      v-model="model.area_prefix_1"
                                      :rules="[
                                        lessThanValidation(
                                          $t('employee.field.areaPrefix0'),
                                          $t('common.less'),
                                          5,
                                          model.area_prefix_1
                                        ),
                                      ]"
                                      required
                                    />
                                  </td>
                                  <td>
                                    <v-text-field
                                      hide-details="auto"
                                      v-model="model.phone_1"
                                      :rules="phoneValid"
                                      required
                                    />
                                  </td>
                                  <td>
                                    <v-text-field
                                      hide-details="auto"
                                      v-model="model.extension_1"
                                      :rules="[
                                        lessThanValidation(
                                          $t('employee.field.extension0'),
                                          $t('common.less'),
                                          6,
                                          model.extension_1
                                        ),
                                      ]"
                                      required
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>{{ $t("employee.tel") }}</td>
                                  <td>
                                    <v-text-field
                                      hide-details="auto"
                                      v-model="model.country_prefix_2"
                                      :rules="[
                                        lessThanValidation(
                                          $t('employee.field.countryPrefix0'),
                                          $t('common.less'),
                                          5,
                                          model.country_prefix_2
                                        ),
                                      ]"
                                      required
                                    />
                                  </td>
                                  <td>
                                    <v-text-field
                                      hide-details="auto"
                                      v-model="model.area_prefix_2"
                                      :rules="[
                                        lessThanValidation(
                                          $t('employee.field.areaPrefix0'),
                                          $t('common.less'),
                                          5,
                                          model.area_prefix_2
                                        ),
                                      ]"
                                      required
                                    />
                                  </td>
                                  <td>
                                    <v-text-field
                                      hide-details="auto"
                                      v-model="model.phone_2"
                                      :rules="phoneValid"
                                      required
                                    />
                                  </td>
                                  <td>
                                    <v-text-field
                                      hide-details="auto"
                                      v-model="model.extension_2"
                                      :rules="[
                                        lessThanValidation(
                                          $t('employee.field.extension0'),
                                          $t('common.less'),
                                          6,
                                          model.extension_2
                                        ),
                                      ]"
                                      required
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>{{ $t("employee.tel") }}</td>
                                  <td>
                                    <v-text-field
                                      hide-details="auto"
                                      v-model="model.country_prefix_3"
                                      :rules="[
                                        lessThanValidation(
                                          $t('employee.field.countryPrefix0'),
                                          $t('common.less'),
                                          5,
                                          model.country_prefix_3
                                        ),
                                      ]"
                                      required
                                    />
                                  </td>
                                  <td>
                                    <v-text-field
                                      hide-details="auto"
                                      v-model="model.area_prefix_3"
                                      :rules="[
                                        lessThanValidation(
                                          $t('employee.field.areaPrefix0'),
                                          $t('common.less'),
                                          5,
                                          model.area_prefix_3
                                        ),
                                      ]"
                                      required
                                    />
                                  </td>
                                  <td>
                                    <v-text-field
                                      hide-details="auto"
                                      v-model="model.phone_3"
                                      :rules="phoneValid"
                                      required
                                    />
                                  </td>
                                  <td>
                                    <v-text-field
                                      hide-details="auto"
                                      v-model="model.extension_3"
                                      :rules="[
                                        lessThanValidation(
                                          $t('employee.field.extension0'),
                                          $t('common.less'),
                                          6,
                                          model.extension_3
                                        ),
                                      ]"
                                      required
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>{{ $t("employee.cel") }}</td><td>
                                    <v-text-field
                                      hide-details="auto"
                                      v-model="model.country_prefix_4"
                                      :rules="[
                                        lessThanValidation(
                                          $t('employee.field.countryPrefix0'),
                                          $t('common.less'),
                                          5,
                                          model.country_prefix_4
                                        ),
                                      ]"
                                      required
                                    />
                                  </td>
                                  <td>
                                    <v-text-field
                                      hide-details="auto"
                                      v-model="model.area_prefix_4"
                                      :rules="[
                                        lessThanValidation(
                                          $t('employee.field.areaPrefix0'),
                                          $t('common.less'),
                                          5,
                                          model.area_prefix_4
                                        ),
                                      ]"
                                      required
                                    />
                                  </td>
                                  <td>
                                    <v-text-field
                                      hide-details="auto"
                                      v-model="model.cellular_no_1"
                                      :rules="phoneValid"
                                      required
                                    />
                                  </td>
                                  <td rowspan="3" class="text-top"></td>
                                </tr>
                                <tr>
                                  <td>{{ $t("employee.cel") }}</td>
                                  <td>
                                    <v-text-field
                                      hide-details="auto"
                                      v-model="model.country_prefix_5"
                                      :rules="[
                                        lessThanValidation(
                                          $t('employee.field.countryPrefix0'),
                                          $t('common.less'),
                                          5,
                                          model.country_prefix_5
                                        ),
                                      ]"
                                      required
                                    />
                                  </td>
                                  <td>
                                    <v-text-field
                                      hide-details="auto"
                                      v-model="model.area_prefix_5"
                                      :rules="[
                                        lessThanValidation(
                                          $t('employee.field.areaPrefix0'),
                                          $t('common.less'),
                                          5,
                                          model.area_prefix_5
                                        ),
                                      ]"
                                      required
                                    />
                                  </td>
                                  <td>
                                    <v-text-field
                                      hide-details="auto"
                                      v-model="model.cellular_no_2"
                                      :rules="phoneValid"
                                      required
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>{{ $t("employee.fax") }}</td>
                                  <td>
                                    <v-text-field
                                      hide-details="auto"
                                      v-model="model.country_prefix_6"
                                      :rules="[
                                        lessThanValidation(
                                          $t('employee.field.countryPrefix0'),
                                          $t('common.less'),
                                          5,
                                          model.country_prefix_6
                                        ),
                                      ]"
                                      required
                                    />
                                  </td>
                                  <td>
                                    <v-text-field
                                      hide-details="auto"
                                      v-model="model.area_prefix_6"
                                      :rules="[
                                        lessThanValidation(
                                          $t('employee.field.areaPrefix0'),
                                          $t('common.less'),
                                          5,
                                          model.area_prefix_6
                                        ),
                                      ]"
                                      required
                                    />
                                  </td>
                                  <td>
                                    <v-text-field
                                      hide-details="auto"
                                      v-model="model.fax"
                                      :rules="faxValid"
                                      required
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                      <v-row class="ma-0 my-2">
                        <v-col cols="12" class="text-center pa-0">
                          <v-btn
                            class="ma-2"
                            outlined
                            fab
                            x-small
                            color="primary"
                            @click="e6 = 2"
                          >
                            <v-icon>mdi-chevron-double-up</v-icon>
                          </v-btn>
                          <v-btn
                            disabled
                            class="ma-2"
                            outlined
                            fab
                            x-small
                            color="primary"
                          >
                            <v-icon>mdi-chevron-double-down</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                  </v-stepper>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <ActivityLogTableDialog :actDialog.sync="actDialog" :docId.sync="employeeId" docType="Employee" />
      <ImageViewDialog
        v-if="profileUrl"
        :profileUrl.sync="profileUrl"
        :dialog.sync="imageViewDialog"
      />
      <RemarksDialog
        :dialog.sync="remarksDialog"
        :docId="employeeId"
        :docType="33"
      />
      <AttachedDocumentsDialog :docId="employeeId" docType="Employee" :dialog.sync="attachedDocumentsDialog" />
      <confirmation-dialog :dialog.sync="confirmationDialog" :delete_item.sync="delete_item" @deleteItem="deleteItem"/>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { getAuxiliaryZtableValueByName, changedFormData } from "@/utils";
import ImageViewDialog from "./Dialog/ImageViewDialog.vue";
import ActivityLogTableDialog from '@/components/activityLog/Dialogs/ActivityLogTableDialog.vue';
import FormSkeleton from "@/components/skeleton/FormSkeleton";
import Alert from "@/components/Alert";
import { formatDateDDMMYYYY, todayDate } from "../../../utils";
import RemarksDialog from "@/components/RemarksDialog";
import AttachedDocumentsDialog from "@/components/AttachedDocumentsDialog";
import ConfirmationDialog from '@/components/ConfirmationDialog';
import { validationMixin } from '@/mixins/validationMixin';

export default {
  name: "EmployeeAction",
  components: {
    ImageViewDialog,
    ActivityLogTableDialog,
    FormSkeleton,
    Alert,
    RemarksDialog,
    AttachedDocumentsDialog,
    ConfirmationDialog
  },
  mixins: [validationMixin],
  data() {
    return {
      message: "",
      alert: false,
      loading: false,
      valid: false,
      model: {
        event_days: '3'
      },
      originModel: {},
      formattedStartDate: "",
      formattedEndDate: "",
      e6: 1,
      previousStep: 1,
      startDateMenu: false,
      endDateMenu: false,
      groupValue: [],
      selectedGroup: null,
      selectedRole: null,
      profileImage: null,
      profileUrl: null,
      imageViewDialog: false,
      actDialog: false,
      deleteProfileId: null,
      copyFormLoading: true,
      showPassword: false,
      remarksDialog: false,
      attachedDocumentsDialog: false,
      setItemHebrew: [],
      confirmationDialog: false,
      delete_item: null,
    };
  },
  computed: {
    employeeValid() {
      return [
        (v) => !!v || this.$t("formRules.employeeRequired"),
        (v) => !/^\s*$/.test(v) || this.$t("formRules.employeeRequired"),
        (v) =>
          !v ||
          (v && v.length <= 250) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("employee.employeeName"),
            type: this.$t("common.less"),
            size: 250,
          }),
      ];
    },
    emailValid() {
      return [
        (v) => !!v || this.$t("formRules.emailRequired"),
        (v) => /.+@.+\..+/.test(v) || this.$t("formRules.emailValidMessage"),
        (v) =>
          !v ||
          (v && v.length <= 64) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("employee.field.email"),
            type: this.$t("common.less"),
            size: 64,
          }),
      ];
    },
    passwordValid() {
      if (!this.employeeId) {
        return [
          (v) => !!v || this.$t("formRules.passwordRequired"),
          (v) =>
            (v && v.length >= 6 && v.length <= 20) ||
            this.$t("formRules.passwordValidMessage"),
        ];
      } else {
        return [
          (v) =>
            !v ||
            (v && v.length >= 6 && v.length <= 20) ||
            this.$t("formRules.passwordValidMessage"),
        ];
      }
    },
    roleValid() {
      return [(v) => !!v || this.$t("formRules.roleRequired")];
    },
    bookkipingNoValid() {
      return [
        (v) => !v || /^[0-9]*$/.test(v) || this.$t("formRules.bookkipingNoInteger"),
        (v) => !v || (v && v.length <= 15) || this.$t("formRules.bookkipingNoValidMessage"),
      ];
    },
    eventDaysValid() {
      return [
        (v) => !v || /^[0-9]*$/.test(v) || this.$t("formRules.eventDaysInteger"),
        (v) => !v || (v && v.length < 3) || this.$t("formRules.eventDaysValidMessage"),
      ];
    },
    phoneValid() {
      return [
        (v) => !v || /^[0-9]*$/.test(v) || this.$t("formRules.phoneNoInteger"),
        (v) =>
          !v ||
          (v && v.length >= 7 && v.length <= 17) ||
          this.$t("formRules.phoneNoValidMessage"),
      ];
    },
    faxValid() {
      return [
        (v) => !v || /^[0-9]*$/.test(v) || this.$t("formRules.faxInteger"),
        (v) =>
          !v ||
          (v && v.length >= 7 && v.length <= 17) ||
          this.$t("formRules.faxValidMessage"),
      ];
    },
    filesValid() {
      return [(v) => !v || v.size <= 2000000 || this.$t("formRules.imageSize")];
    },
    employeeId() {
      return this.$route.params.employee_id;
    },
    copiedEmployee() {
      return this.$route.params.copiedModel;
    },
    ...mapGetters({
      employeeById: "employee/employeeById",
      rolesList: "rolesList",
      employeeQuery: "employee/employeeQuery",
      accessRight: "accessRight",
      user: "user",
      profileById: "profile/profileById",
      locale: "locale",
      moreThanOneUserLogout: "moreThanOneUserLogout",
      remarksList: 'remarks/remarksList',
    }),
  },
  watch: {
    rolesList() {
      this.setItemHebrew = this.rolesList.map(h => {
        return {
          id: h.id,
          name: h.name,
          name_he: h.name_he ? h.name_he : h.name,
        }
      })
    },
    "model.starting_date"(val) {
      this.formattedStartDate = this.profileById.date_format
        ? formatDateDDMMYYYY(val)
        : val;
    },
    "model.end_date"(val) {
      this.formattedEndDate = this.profileById.date_format
        ? formatDateDDMMYYYY(val)
        : val;
    },
    profileById(val) {
      this.formattedEndDate = val.date_format
        ? formatDateDDMMYYYY(this.model.starting_date)
        : this.model.starting_date;
      this.formattedStartDate = val.date_format
        ? formatDateDDMMYYYY(this.model.end_date)
        : this.model.end_date;
    },
    e6() {
      if (this.valid) {
        this.alert = false;
      } else {
        this.message = "formRules.formAlert";
        this.alert = true;
      }
    },
    employeeById() {
      if (Object.keys(this.employeeById).length > 0) {
        if (
          this.user.role.slug === "admin" ||
          this.user.role.slug === "manager" ||
          this.user.id === this.employeeById.user_id
        ) {
          this.model = Object.assign({}, this.model, this.employeeById);
          if (this.model.starting_date) {
            this.model.starting_date = new Date(this.model.starting_date)
              .toISOString()
              .substring(0, 10);
          }
          if (this.model.end_date) {
            this.model.end_date = new Date(this.model.end_date)
              .toISOString()
              .substring(0, 10);
          }
          this.originModel = Object.assign({}, this.originModel, this.model);
        } else {
          this.callBackFunction();
        }
      }
    },
    async moreThanOneUserLogout() {
      if (this.moreThanOneUserLogout && this.model.id) {
        await this.$store.dispatch("PostDocumentOpenAction", {
          table: "Employee",
          id: this.model.id,
          is_locked: false,
        });
        this.$store.dispatch("LogOut");
        this.$router.push({ name: "login" });
      }
    },
  },
  async created() {
    window.addEventListener("beforeunload", this.handler);
  },
  async mounted() {
    if (this.$route.params.copiedModel) {
      this.model = this.$route.params.copiedModel;
      this.selectedGroup = this.model.group;
      if (this.model.user) {
        this.selectedRole = this.model.user.role_id;
        this.model.role_id = this.model.user.role_id;
        this.model.email = this.model.user.email;
      }
      if (this.model.media) {
        this.profileUrl = this.model.media.file_path;
      }
      this.model.email = null;
      this.copyFormLoading = false;
    } else if (this.$route.params.employee_id) {
      await this.$store.dispatch(
        "employee/GetEmployeeById",
        this.$route.params.employee_id
      );
      if (!this.model.is_locked && this.$route.params.employee_id) {
        await this.$store.dispatch("PostDocumentOpenAction", {
          table: "Employee",
          id: this.$route.params.employee_id,
          is_locked: true,
        });
      }
      this.updateEmployeeData();
    }
    if (this.employeeId) {
      this.$store.dispatch('remarks/GetRemarks', {where: 'document_type|'+33, where_and: 'document_id|'+this.employeeId})
    }
    this.$store.dispatch("profile/GetCompanyById");
    this.$store.dispatch("GetRoles", { order_by: "name|asc" });
    let tableNameValue = await getAuxiliaryZtableValueByName(1);
    if (Object.keys(tableNameValue).length > 0) {
      this.groupValue = tableNameValue.ztables.sort((a, b) =>
        a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
          ? 1
          : -1
      );
    }
  },
  beforeDestroy() {
    if (
      (this.model.id &&
        !this.moreThanOneUserLogout &&
        this.model.is_locked &&
        this.model.user_id2 == this.user.id) ||
      (this.model.id && !this.model.user_id2 && !this.moreThanOneUserLogout)
    ) {
      this.$store.dispatch("PostDocumentOpenAction", {
        table: "Employee",
        id: this.model.id,
        is_locked: false,
      });
    }
    this.$refs.form ? this.$refs.form.reset() : "";
    (this.model = {}), this.$store.commit("employee/SET_EMPLOYEE_BY_ID", {});
    this.$store.commit("SET_USER_LOGOUT", false);
    window.removeEventListener("beforeunload", this.handler);
  },
  methods: {
    todayDate: todayDate,
    async handler(event) {
      if (
        (this.model.id &&
          !this.moreThanOneUserLogout &&
          this.model.is_locked &&
          this.model.user_id2 == this.user.id) ||
        (this.model.id && !this.model.user_id2 && !this.moreThanOneUserLogout)
      ) {
        await this.$store.dispatch("PostDocumentOpenAction", {
          table: "Employee",
          id: this.model.id,
          is_locked: false,
        });
      }
      event.preventDefault();
    },
    updateEmployeeData() {
      this.selectedGroup = this.model.group;
      if (this.model.user) {
        this.selectedRole = this.model.user.role_id;
        this.model.email = this.model.user.email;
      }
      if (this.model.media) {
        this.profileUrl = this.model.media.file_path;
      }
    },
    callBackFunction() {
      this.$router.push({
        name: "Employee",
        query: {
          ...this.employeeQuery,
        },
      });
    },
    copyEmployee() {
      let copyModel = this.originModel;
      this.$router.push({
        name: "addEmployee",
        params: {
          copiedModel: copyModel,
        },
      });
    },
    accordionStepper(current) {
      if (this.previousStep == current && this.previousStep != 4) {
        this.e6 = 4;
        this.previousStep = 4;
      } else {
        this.previousStep = current;
      }
    },
    lessThanValidation(fieldValue, typeValue, sizeValue, v) {
      return (
        !v ||
        (v && v.length <= sizeValue) ||
        this.$t("formRules.lengthValidMessage", {
          fieldName: fieldValue,
          type: typeValue,
          size: sizeValue,
        })
      );
    },
    previewImage() {
      if (this.profileImage) {
        this.profileUrl = URL.createObjectURL(this.profileImage);
      } else {
        if (this.model.media && !this.deleteProfileId) {
          this.profileUrl = this.model.media.file_path;
        } else {
          this.profileUrl = "";
        }
      }
    },
    deleteProfileImage(id) {
      this.deleteProfileId = id;
      this.model.media_id = null;
      this.profileUrl = null;
    },
    async saveEmployee() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let data;
        if (this.selectedGroup && Object.keys(this.selectedGroup).length > 0) {
          this.model.group_id = this.selectedGroup.id;
        }
        if (this.selectedRole && Object.keys(this.selectedRole).length > 0) {
          this.model.role_id = this.selectedRole.id;
        }
        // upload image
        if (this.profileImage) {
          let formData = new FormData();
          formData.append("image", this.profileImage);
          if (this.model.media_id) {
            await this.$store.dispatch("media/EditMedia", {
              media_id: this.model.media_id,
              formData: formData,
            });
          } else {
            let imageData = await this.$store.dispatch(
              "media/SetMedia",
              formData
            );
            if (imageData) {
              this.model.media_id = imageData.data.id;
            }
          }
        }
        if (this.deleteProfileId) {
          this.$store.dispatch("media/DeleteMediaById", this.deleteProfileId);
        }
        if (this.$route.params.employee_id) {
          // save only update model value using changedFormData from utils
          let saveData = changedFormData(this.model, this.originModel);
          data = await this.$store
            .dispatch("employee/EditEmployee", {
              employee_id: this.$route.params.employee_id,
              employee: saveData,
            })
            .catch((e) => {
              this.loading = false;
              console.log(e);
            });
          if (data) {
            this.$refs.form.reset();
            this.loading = false;
            await this.$store.dispatch(
              "employee/GetEmployeeById",
              data.data.id
            );
            this.$store.dispatch("GetRoles", { order_by: "name|asc" });
            let tableNameValue = await getAuxiliaryZtableValueByName(1);
            if (Object.keys(tableNameValue).length > 0) {
              this.groupValue = tableNameValue.ztables.sort((a, b) =>
                a.description.toLocaleLowerCase() >
                b.description.toLocaleLowerCase()
                  ? 1
                  : -1
              );
            }
            this.updateEmployeeData();
          }
        } else if (this.$route.params.copiedModel) {
          let copyModel = this.model;
          delete copyModel.id;
          delete copyModel.created_at;
          delete copyModel.updated_at;
          delete copyModel.user_id;
          delete copyModel.user;
          delete copyModel.group;
          data = await this.$store
            .dispatch("employee/SetEmployee", this.model)
            .catch((e) => {
              this.loading = false;
              console.log(e);
            });
          if (data) {
            this.$refs.form.reset();
            this.loading = false;
            if (this.accessRight.includes("edit")) {
              await this.$router
                .push("/employee/editEmployee/" + data.data.id)
                .catch(() => {});
            } else {
              this.callBackFunction();
            }
          }
        } else {
          data = await this.$store
            .dispatch("employee/SetEmployee", this.model)
            .catch((e) => {
              this.loading = false;
              console.log(e);
            });
          if (data) {
            this.$refs.form.reset();
            this.loading = false;
            if (this.accessRight.includes("edit")) {
              await this.$router
                .push("/employee/editEmployee/" + data.data.id)
                .catch(() => {});
            } else {
              this.callBackFunction();
            }
          }
        }
      } else {
        this.message = "formRules.formAlert";
        this.alert = true;
      }
    },
    async deleteItem() {
      await this.$store.dispatch('employee/DeleteEmployeeItemById', this.employeeId)
      this.$router.push("/employee")
    },
  },
};
</script>
